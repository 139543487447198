import React, { useEffect, useState } from 'react';
import {ethers, utils} from 'ethers';
import {Link} from "react-router-dom";
import {NETWORK_STATE_DISCONNECTED, NETWORK_STATE_LOADING, NETWORK_STATE_WRONG_NETWORK, NETWORK_STATE_LOADED } from '../constants';

const {MerkleTree} = require("merkletreejs");
const keccak256 = require("keccak256");
const airdrop = require("../data/whitelist.json");


function hashToken(tokenId, account) {
  return Buffer.from(ethers.utils.solidityKeccak256(['uint256', 'address'], [tokenId, account]).slice(2), 'hex');
}

const merkleTree = new MerkleTree(Object.entries(airdrop).map(token => hashToken(...token)), keccak256, { sortPairs: true });

function Whitelist({networkState, connection, state: {mintPrice}}) {
  const [onWhitelist, setOnWhitelist] = useState(false);
  const [alreadyMinted, setAlreadyMinted] = useState(false);
  const [whitelistMintingEnabled, setWhitelistMintingEnabled] = useState(false);

  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetch() {
      let foundWhitelist = false;
      let foundTokenId = 0;
      if (networkState === NETWORK_STATE_LOADED && connection?.contract) {
        const whitelistMintingEnabled = await connection.contract.whitelistMintEnabled();
        setWhitelistMintingEnabled(whitelistMintingEnabled);

        const addr = await connection.provider.getSigner(0).getAddress();
        for (let tokenId in airdrop) {
          if (airdrop[tokenId].toLowerCase() === addr.toLowerCase()) {
            foundWhitelist = true;
            foundTokenId = tokenId;
            break;
          }
        }
        if (foundWhitelist) {
          setOnWhitelist(true);
          try {
            await connection.contract.ownerOf(foundTokenId);
            setAlreadyMinted(true);
          } catch (e) {
            if (e?.data?.message !== `Error: VM Exception while processing transaction: reverted with reason string 'ERC721: owner query for nonexistent token'`) {
              console.log(e); // nop
            }
          }
        }
      }
    }
    fetch();

    if (networkState === NETWORK_STATE_LOADED && connection?.contract) {
      connection.contract.on("Mint", () => {
        fetch();
      });
    }
  }, [networkState, connection]);

  async function whitelistMint() {
    let foundWhitelist = false;
    let foundTokenId = 0;
    const signer = connection.contract.connect(connection.provider.getSigner(0));
    const addr = await connection.provider.getSigner(0).getAddress();
    for (let tokenId in airdrop) {
      if (airdrop[tokenId].toLowerCase() === addr.toLowerCase()) {
        foundWhitelist = true;
        foundTokenId = tokenId;
        break;
      }
    }
    if (foundWhitelist) {
      const proof = merkleTree.getHexProof(hashToken(foundTokenId, airdrop[foundTokenId]));
      try {
        await signer.whitelistMint(airdrop[foundTokenId], foundTokenId, proof, {value: mintPrice});
      } catch (e) {
        setError(e.message);
      }
    }
  }
  
  return (
    <div id="hero-text">
      <h1>KitPics!</h1>
      
      {error && <div class="alert alert-danger" role="alert">{error}</div>}

      {networkState === NETWORK_STATE_DISCONNECTED && <p>Please install and authorize Metamask.</p>}

      {networkState === NETWORK_STATE_LOADING && <p>Loading...</p>}

      {networkState === NETWORK_STATE_WRONG_NETWORK && <p>Non-mainnet chain detected. Please switch to the Ethereum network!</p>}

      {networkState === NETWORK_STATE_LOADED && <>
        {onWhitelist && <>
          {whitelistMintingEnabled && <>
            {alreadyMinted && <p>The whitelist slot for this account has already been claimed.</p>}

            {!alreadyMinted && <>
              <p>Congratulations! You have a whitelisted mint slot.</p>
              <div className="input-group mb-3">

                <button type="button" className="btn btn-dark" id="mint" onClick={whitelistMint}>Mint! ({utils.formatEther(mintPrice)} ETH)</button>
              </div>
            </>}
          </>}

          {!whitelistMintingEnabled && <p>Whitelist minting has been disabled.</p>}
        </>}

        {!onWhitelist && <p>This account does not have a whitelist slot. <br /> Please double check which account you're currently using!</p>}
      </>}

      <p><Link to="/">Click here to return to normal minting!</Link></p>
    </div>
  );
}

export default Whitelist;