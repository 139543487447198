import React, { useEffect, useState } from 'react';
import {ethers, utils} from 'ethers';
import abi from '../abi/KitPics.json';
import {HashRouter as Router, Switch, Route, Link} from "react-router-dom";
import Whitelist from './Whitelist';
import { NETWORK_STATE_DISCONNECTED, NETWORK_STATE_LOADING, NETWORK_STATE_WRONG_NETWORK, NETWORK_STATE_LOADED } from '../constants';

const CONTRACT_ADDRESS = '0xB1bb22c3101E7653d0d969F42F831BD9aCCc38a5';
const LAUNCHED = true;
const CORRECT_CHAIN_ID = 1;

function App() {
  const [networkState, setNetworkState] = useState(NETWORK_STATE_DISCONNECTED);

  const [connection, setConnection] = useState(null);
  const [state, setState] = useState({
    mintPrice: utils.parseEther("0.06"),
    totalSupply: '...',
    supplyLimit: '...',
    mintEnabled: false,
  });

  const [mintAmount, setMintAmount] = useState(1);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function load() {
      if (!LAUNCHED) {
        return;
      }

      if (typeof web3 === 'undefined') {
        return; // not connected, default to unconnected state
      }
      const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      await provider.send("eth_requestAccounts", []);
      setNetworkState(NETWORK_STATE_LOADED);

      // nasty hack, thanks neso
      provider.on("accountsChanged", async () => {
        window.location.reload();
      });
      provider.on("chainChanged", async () => {
        window.location.reload();
      });

      const network = await provider.getNetwork();
      if (network.chainId !== CORRECT_CHAIN_ID) {
        setConnection({provider});
        setNetworkState(NETWORK_STATE_WRONG_NETWORK);
        return;
      }

      const contract = new ethers.Contract(CONTRACT_ADDRESS, abi, provider).connect(provider.getSigner());
      setConnection({contract, provider});

      contract.on("Mint", () => {
        fetchData({contract, provider});
      });
      provider.on("block", () => {
        fetchData({contract, provider});
      });

      fetchData({contract, provider});
    }
    load();
  }, []);

  async function fetchData({contract, provider}) {
    const [mintPrice, totalSupply, supplyLimit, mintEnabled] = await Promise.all([
      contract.MINT_FEE(), contract._tokenIdTracker(), contract.maxTokens(), contract.mintEnabled()
    ]);
    setState({
      ...state,
      mintPrice: mintPrice,
      totalSupply: totalSupply.toNumber(),
      supplyLimit: supplyLimit.toNumber(),
      mintEnabled: mintEnabled,
    });
    setNetworkState(NETWORK_STATE_LOADED);
  }
  
  async function mint() {
    const amount =  mintAmount || 1;
    const signer = connection.contract.connect(connection.provider.getSigner(0));
    try {
      await signer.mint(amount, {value: state.mintPrice.mul(amount)});
    } catch (e) {
      setError(e.message);
    }
  }

  function setMintAmountBounded(amount) {
    if (amount !== '') {
      if (amount < 1) {
        amount = 1;
      }
      if (amount > 25) {
        amount = 25;
      }
    }
    setMintAmount(amount);
  }

  return (
    <Router>
      <div id="content">
        <div id="header">
          <button type="button" className="btn btn-light">
            {LAUNCHED && <>
              {networkState === NETWORK_STATE_DISCONNECTED && <>Connecting...</>}
              {networkState !== NETWORK_STATE_DISCONNECTED && <>Connected</>}
            </>}
            {!LAUNCHED && <>Launching Soon</>}
          </button>
        </div>
        <div id="hero">
          <img src="/mystery_fox.jpg" alt="hero" />
          <Switch>
            <Route path="/whitelist">
              <Whitelist {...{connection, networkState, state}} />
            </Route>

            <Route path="/">
              {LAUNCHED && <>
                <div id="hero-text">
                  <h1>KitPics!</h1>
                  {error && <div class="alert alert-danger" role="alert">{error}</div>}

                  {networkState === NETWORK_STATE_DISCONNECTED && <p>Please install and authorize Metamask.</p>}

                  {networkState === NETWORK_STATE_LOADING && <p>Loading...</p>}

                  {networkState === NETWORK_STATE_WRONG_NETWORK && <p>Non-mainnet chain detected. Please switch to the Ethereum network!</p>}

                  {networkState === NETWORK_STATE_LOADED && <>
                    {state.mintEnabled && state.totalSupply < state.supplyLimit && <>
                      <div className="input-group mb-3">
                        <input type="number" min="1" max="25" placeholder="Amount (max 25)" className="form-control" value={mintAmount} onChange={e => setMintAmountBounded(e.target.value)} />
                        <button type="button" className="btn btn-dark" id="mint" onClick={mint}>Mint! ({utils.formatEther(state.mintPrice.mul(mintAmount || 1))} ETH)</button>
                      </div>
                      <p><strong>{state.totalSupply}/{state.supplyLimit}</strong> Minted/Reserved!</p>
                    </>}

                    {!state.mintEnabled && <p>Minting is currently disabled.</p>}

                  </>}

                  <p><Link to="/whitelist">Click here if you're on the whitelist!</Link></p>

                  <p>View the collection on <a href="https://opensea.io/collection/kitpics" target="_blank">OpenSea</a>.</p>
                </div>
              </>}
            </Route>
          </Switch>

        </div>
        <div id="footer">
          <a href="https://discord.gg/QwHAtKNg6N" target="_blank"><button type="button" className="btn btn-light"><i className="bi-discord" /></button></a>
          <a href="https://twitter.com/KitPicsNFT" target="_blank"><button type="button" className="btn btn-light"><i className="bi-twitter" /></button></a>
          <a href="https://opensea.io/collection/kitpics/" target="_blank"><button type="button" className="btn btn-light"><i className="bi-search" /></button></a>
        </div>
      </div>
    </Router>
  );
}

export default App;
